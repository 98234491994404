






import { Component, Vue as VueType, Prop, Watch } from 'vue-property-decorator'
// 后台 ueditor 富文本显示
import EditorAudio from './components/editor-audio.vue'
import EditorVideo from './components/editor-video.vue'
import EditorContent from './components/editor-content.vue'
import Vue from 'vue'

  @Component({
    components: {
    }
  })
export default class UeditorDisplay extends VueType {
    @Prop()html?: string

    imgUrls: Array<any> = []

    get filterHtml() {
      let html = this.html
      let tempElm: any = window.document.createElement('div')
      tempElm.innerHTML = html
      let videoCount = this.filterVideo(tempElm)
      let audioCount = this.filterAudio(tempElm)
      this.filterContent(tempElm)

      let filterHtml = tempElm.innerHTML

      let self = this
      return {
        ...Vue.compile('<div>' + filterHtml + '</div>'),
        components: {
          EditorAudio,
          EditorContent,
          EditorVideo
        },
        mounted() {
          self.wxVideo()
        },
        methods: {
          stopAll($triggerComponent: any) {
            for (let i = 0; i < audioCount; i++) {
              let audio = (this as any).$refs['audio_' + i]
              if ($triggerComponent !== audio) {
                audio.stop()
              }
            }
            for (let i = 0; i < videoCount; i++) {
              let video = (this as any).$refs['video_' + i]
              if ($triggerComponent !== video) {
                video.stop()
              }
            }
          }
        }
      }
    }

    @Watch('filterHtml')
    onFilterHtmlChanged() {
      this.$nextTick(() => {
        this.imgLoaded()
      })
    }

    mounted() {
      this.init()
    }

    init() {
      this.wxVideo()
      let ueditor: any = this.$refs.ueditorWrap
      let imgs = ueditor.getElementsByTagName('img')
      for (let i = 0; i < imgs.length; i++) {
        this.imgUrls.push(imgs[i].src)
      }

      this.imgLoaded()
    }
    imgLoaded() {
      let imgUrls = this.imgUrls
      let len = imgUrls.length
      let self = this
      if (len > 0) {
        let loaded = 0
        for (let i = 0; i < len; i++) {
          let img = new window.Image()
          img.onerror = img.onload = function () {
            loaded = loaded + 1
            if (loaded === len) {
              self.$emit('imgsReady')
            }
          }
          img.src = imgUrls[i]
        }
      } else {
        this.$emit('imgsReady')
      }
    }
    filterVideo(elm: any) {
      let videoBox = elm.querySelectorAll('.ds-video-box')
      let count = 0

      for (let i = 0; i < videoBox.length; i++) {
        let curBox = videoBox[i]
        let ref = 'video_' + count
        count++
        // 解析参数
        try {
          let videoPath = curBox.getAttribute('ds-video-src')
          let videoCover = curBox.getAttribute('ds-video-cover')
          let videoHtml = '<editor-video cover="' + videoCover + '" ref="' + ref + '" @play="stopAll" path="' + videoPath + '"></editor-video>'
          let newElm = window.document.createElement('div')
          newElm.innerHTML = videoHtml
          curBox.parentNode.replaceChild(newElm, curBox)
        } catch (e) {}
      }
      return count
    }
    filterAudio(elm: any) {
      let audioBox = elm.querySelectorAll('.ds-audio-box')
      let count = 0
      for (let i = 0; i < audioBox.length; i++) {
        let curBox = audioBox[i]
        let ref = 'audio_' + count
        count++
        // 解析参数
        try {
          let audioPath = curBox.getAttribute('ds-audio-src')
          let audioTitle = curBox.querySelectorAll('.ds-audio-title')
          let audioDuration = curBox.querySelectorAll('.ds-audio-duration')

          let audioHtml = '<editor-audio ref="' + ref + '" @play="stopAll" duration="' + audioDuration + '" desc="' + audioTitle + '" path="' + audioPath + '"></editor-audio>'
          let newElm = window.document.createElement('div')
          newElm.innerHTML = audioHtml
          curBox.parentNode.replaceChild(newElm, curBox)
        } catch (e) {}
      }
      return count
    }
    filterContent(elm: any) {
      let contentBox = elm.querySelectorAll('.ds-content-box')
      let count = 0
      for (let i = 0; i < contentBox.length; i++) {
        let curBox = contentBox[i]
        let ref = 'content_' + count
        count++
        // 解析参数
        try {
          let img = curBox.getAttribute('ds-content-img')
          let contentId = curBox.getAttribute('ds-content-id')
          let contentType = curBox.getAttribute('ds-content-type')
          let contentLink = curBox.getAttribute('ds-content-link')
          let contentTitle = curBox.getAttribute('ds-content-title')
          let relatedContentType = curBox.getAttribute('ds-related-content-type')
          let relatedContentId = curBox.getAttribute('ds-related-content-id')

          let contentHtml = '<editor-content ref="' + ref + '" content-link="' + contentLink + '" related-content-id="' + relatedContentId + '" related-content-type="' + relatedContentType + '" coverimg="' + img + '" tit="' + contentTitle + '" content-id="' + contentId + '" content-type="' + contentType + '"></editor-content>'
          let newElm = window.document.createElement('div')
          newElm.innerHTML = contentHtml
          curBox.parentNode.replaceChild(newElm, curBox)
        } catch (e) {}
      }
      return count
    }
    getQuery(name: any, url: any) {
      let u = arguments[1] || window.location.search
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let r = u.substr(u.indexOf('?') + 1).match(reg)
      return r != null ? r[2] : ''
    }
    wxVideo() {
      let iframe = (this.$refs.ueditorWrap as any).querySelectorAll('iframe')
      for (let i = 0, il = iframe.length; i < il; i++) {
        let a = iframe[i]
        let src_ = a.getAttribute('src') || a.getAttribute('data-src') || ''
        // 不是腾讯视频的不处理
        if (!/http(s)*:\/\/v\.qq\.com\/iframe\/(preview|player)\.html\?/.test(src_) &&
          !/http(s)*:\/\/mp\.weixin\.qq\.com\/mp\/readtemplate\?t=pages\/video_player_tmpl/.test(src_)
        ) {
          continue
        }
        let vid = this.getQuery('vid', src_)
        // 没 vid 的不处理
        if (!vid) {
          continue
        }
        vid = vid.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
        a.removeAttribute('src')
        if (a.getAttribute('data-mpvid')) {
          a.setAttribute('src', window.location.protocol + '//mp.weixin.qq.com/mp/readtemplate?t=pages/video_player_tmpl&auto=0&vid=' + vid)
        } else {
          a.setAttribute('src', window.location.protocol + '//v.qq.com/iframe/player.html?vid=' + vid + '&auto=0')
        }
      }
    }
}
