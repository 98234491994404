











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import GVideo from '@/modules/common/components/g-video.vue'

  @Component({
    components: {
      GVideo
    }
  })
export default class EditorVideo extends Vue {
    @Prop()
    path!: string
    @Prop()
    cover!: string

    stop() {
      (this.$refs.gVideo as GVideo).pause()
    }
    eventHandler(msg: any) {
      if (msg === 'play') {
        this.$emit('play', this)
      }
    }
}
