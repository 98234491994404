<template>
  <div class="content-wrap" @click="goState()">
    <div class="wrap-h" :style="{backgroundImage:'url(' + coverimg + ')'}">
    </div>
    <div class="wrap-f" v-html="tit"></div>
  </div>
</template>
<script>
export default {
  props: [
    'coverimg',
    'tit',
    'content-id',
    'related-content-type',
    'content-link',
    'related-content-id',
    'content-type'
  ],
  computed: {
    link() {
      let type = this.contentType
      let id = this.contentId
      let obj = {
        type,
        id
      }
      if (type === 'course') {
        // 课程补充 课程类型
        obj.course_type = 'mixed'
      } else if (type === 'feedback' || type === 'survey') {
        // 表单 问卷 字段映射 课程类型
        obj.url = this.contentLink
      } else if (type === 'fight_group') {
        // 拼团 添加相应字段
        obj.related_content_type = this.relatedContentType
        obj.related_content_id = this.relatedContentId
      } else if (type === 'class') {
        // 拼团 添加相应字段
        obj.name = this.tit
      }
      return obj
    }
  },
  methods: {
    goState () {
      let link = this.link
      let h5Route = link
      if (link.type === 'course') {
        this.$router.push({
          name: 'course',
          params: {
            id: link.id
          }
        })
      } else if (['audio', 'video', 'article'].indexOf(link.type) !== -1) {
        this.$router.push({
          name: 'content',
          params: {
            type: link.type,
            id: link.id
          }
        })
      } else {
        this.$showH5qrCode(h5Route, '', 'server')
      }
    }
  },
  components: {
  }
}
</script>
<style scoped lang='scss' rel="stylesheet/scss">
  .content-wrap{
    display:flex;
    background:#FCFCFC;
    border:1px solid #eaeaea;
    padding:5px;
    border-radius:2px;
    .wrap-h{
      width:150px;
      height: 94px;
      background-size: cover;
      background-position: center center;
      border-radius:2px;
    }
    .wrap-f{
      flex:1;
      margin-top:8px;
      margin-left:5px;
      line-height:1.4em;
      height:4.2em;
      font-size:16px;
    }
  }
</style>
