





import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import GAudio from '@/modules/common/components/g-audio.vue'
  @Component({
    components: {
      GAudio
    }
  })
export default class EditorAudio extends Vue {
    @Prop()
    path!: string
    @Prop()
    desc!: string
    @Prop()
    duration!: string

    stop() {
      (this.$refs.gAudio as GAudio).pause()
    }
    eventHandler(msg: any) {
      let name = msg.name
      let value = msg.value
      if(name === 'playing' && value) {
        this.$emit('play', this)
      }
    }
}
