































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import GVideoCopyrightMask from '@/modules/common/components/g-video-copyright.vue'

let id = 0
@Component({
  components: {
    GVideoCopyrightMask
  }
})
export default class GVideo extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  defautErr!: boolean
  @Prop({
    type: Boolean,
    default: false
  })
  live!: boolean
  @Prop({
    type: String,
    default: 'http://ivi.bupt.edu.cn/hls/cctv6hd.m3u8'
  })
  contentpath!: string
  @Prop({
    type: String,
    default: 'https://sina-image.duanshu.com/2019/09/23/13/4bej13g0645928574d/common/content/1569218110541_737215.jpg?imageMogr2/auto-orient/thumbnail/640x400/'
  })
  coverImg!: string
  @Prop({
    type: Boolean,
    default: true
  })
  autoReload!: boolean

  intervalId!: number  // 心跳计时器
  msgts: number = 0
  ts: number = 0
  errorText: string = ''
  playing: boolean = false
  qcVideo: any = null
  id: string = 'J_video' + id
  copyrigntDoing: boolean = false

  @Watch('contentpath')
  onContentpathChanged() {
    if (this.autoReload) {
      this.reloadPlayer()
    }
  }

  created() {
    id++
  }
  mounted() {
    this.initPlayer()
  }
  beforeDestroy () {
    this.endCheck()
    this.destroyPlayer()
  }

  // 初始货
  initPlayer () {
    const self = this
    let videoParam: any = {
      width: '100%',
      height: '100%',
      flash: false,
      live: this.live,
      wording: {
        '2': '网络错误，请检查网络配置或者播放链接是否正确',
        '13': '直播已结束，请稍后再来',
        '2032': '请求视频失败，请检查网络',
        '2048': '请求m3u8文件失败，可能是网络错误或者跨域问题'
      },
      listener(msg: any) {
        self.$emit('listenerEvent', msg)
        self.msgts = msg.ts
        if (msg.type === 'play') {
          self.playing = true
          self.startCheck()
        } else if (msg.type === 'pause') {
          self.endCheck()
        } else if (msg.type === 'error') {
          // raven 错误记录
          self.endCheck()
          self.error(msg)
        }
      }
    }
    if (this.contentpath.indexOf('.flv') !== -1) {
      videoParam.flv = this.contentpath
      videoParam.h5_flv = true
    } else {
      videoParam.m3u8 = this.contentpath
    }
    this.qcVideo = new window.TcPlayer(this.id, videoParam)
  }
  startCheck() {
    this.endCheck()
    this.intervalId = window.setInterval(() => {
      if (this.ts === this.msgts) {
        window.clearInterval(this.intervalId)
      } else {
        this.ts = this.msgts
      }
    }, 3000)
  }
  endCheck() {
    window.clearInterval(this.intervalId)
  }
  // 播放
  playVideo() {
    if (!this.qcVideo) {
      return
    }
    this.copyrigntDoing = true
    return this.qcVideo.play()
  }
  // 销毁
  destroyPlayer() {
    if (!this.qcVideo) {
      return
    }
    this.qcVideo.destroy()
    this.copyrigntDoing = false
    this.qcVideo = null
  }
  reloadPlayer() {
    this.errorText = ''
    this.copyrigntDoing = false
    this.qcVideo && this.qcVideo.load(this.contentpath)
  }
  pause() {
    this.copyrigntDoing = false
    this.qcVideo && this.qcVideo.pause()
  }
  error(e: any) {
    let map: any = {
      '2': '网络错误，请检查网络配置或者播放链接是否正确',
      '13': '直播已结束，请稍后再来',
      '2032': '请求视频失败，请检查网络',
      '2048': '请求m3u8文件失败，可能是网络错误或者跨域问题'
    }
    let {code, reason} = e.detail || e
    let msg = map[code] || reason
    this.errorText = `[${code}]: ${msg}`
  }
  reTry() {
    this.destroyPlayer()
    setTimeout(() => {
      this.initPlayer()
    }, 1000)
  }

  changeSpeed(times: number) {
    let el = this.qcVideo.video.el
    let defaultValue = el.defaultPlaybackRate
    let n = defaultValue * times
    el.playbackRate = n
  }
}
